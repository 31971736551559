import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { Link as RemixLink } from "@remix-run/react";
import type { AppUrl } from "@tamarack/shared/AppUrl";

export type BaseLinkProps<T> = Omit<T, "to"> & {
  to: AppUrl;
};

export type LinkProps = BaseLinkProps<RemixLinkProps>;

export default function Link({ to, ...linkProps }: LinkProps) {
  return <RemixLink to={to.toString()} prefetch="intent" {...linkProps} />;
}
