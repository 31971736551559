import type { ButtonProps } from "@tamarack/ui/Button";
import type { KeyboardEventHandler, PropsWithChildren, ReactElement, ReactNode } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { twMerge } from "tailwind-merge";
import Button, { generateDisabledProps } from "./Button";
import buttonStyles from "./Button/styles.module.css";
import Card from "./Card";
import ClickAwayListener from "./ClickAwayListener";
import IconButton from "./IconButton";
import type { PopupProps } from "./Popup";
import Popup from "./Popup";

export type ButtonDialogProps = Omit<ButtonProps, "children" | "title"> & {
  /**
   * CSS classes for the button
   */
  buttonClassName?: string;
  /**
   * What type of button to render
   * @default default
   */
  buttonType?: "default" | "icon";
  /**
   * CSS classes for the card
   */
  cardClassName?: string;
  /**
   * The content for the dialog
   */
  children: PropsWithChildren["children"] | ((close: () => void, isOpen: boolean) => ReactNode);
  /**
   * The classes for the container around the button (used for the click away listener)
   */
  containerClassName?: string;
  /**
   * Register a click handler on the card of the dialog
   */
  onCardClick?: () => void;
  /**
   * Keydown event listener when focus is on the card
   */
  onCardKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined;
  /**
   * Called with the dialog closes
   */
  onClose?: () => void;
  /**
   * Called with the dialog opens
   */
  onOpen?: () => void;
  /**
   * Keydown event listener when focus is on the popup. The popup is the component responsibile
   * for positioning the dialog to the anchor element
   */
  onPopupKeyDown?: PopupProps["onKeyDown"];
  /**
   * Pass in state management so that the parent can handle it
   */
  parentState?: ButtonDialogState;
  /**
   * Where to put the dialog
   * @default "bottom-start"
   */
  placement?: PopupProps["placement"];
  /**
   * CSS classes for the popper. The popper is the component responsibile
   * for positioning the dialog to the anchor element
   */
  popupClassName?: string;
  /**
   * The text that appears in the button
   */
  title?: ReactNode | ReactElement | string;
};

export const BUTTON_DIALOG_CARD_IDENTIFIER = "tm-button-dialog-card";

const ButtonDialog = forwardRef<HTMLButtonElement, ButtonDialogProps>(
  (
    {
      buttonClassName,
      buttonType = "default",
      cardClassName,
      children,
      onCardClick,
      onCardKeyDown,
      onClose,
      onOpen,
      onPopupKeyDown,
      parentState,
      placement,
      popupClassName,
      title,
      disabled,
      disabledDescription,
      containerClassName,
      ...buttonProps
    },
    ref
  ) => {
    const internalState = useButtonDialogState();
    const { anchorEl, setAnchorEl, close, open, isOpen } = parentState ?? internalState;
    const cardRef = useRef<HTMLDivElement>();
    const internalButtonRef = useRef<HTMLButtonElement | null>(null);

    const ButtonComponent = buttonType === "icon" ? IconButton : Button;

    useEffect(() => {
      if (anchorEl) {
        setTimeout(() => {
          cardRef.current?.focus();
        }, 0);
      }
    }, [anchorEl]);

    const closeDialog = () => {
      if (isOpen) {
        close();
        onClose?.();
      }
    };

    return (
      <ClickAwayListener onClickAway={closeDialog}>
        <div className={containerClassName}>
          <ButtonComponent
            ref={(node) => {
              internalButtonRef.current = node;

              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                // @ts-ignore
                ref.current = node;
              }
            }}
            type="button"
            role="button"
            {...generateDisabledProps({ disabled, disabledDescription })}
            {...buttonProps}
            className={`${anchorEl ? buttonStyles.active : ""} ${buttonClassName}`}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "ArrowDown") {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();

                e.currentTarget.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
              }

              buttonProps.onKeyDown?.(e);
            }}
            onMouseDown={(e) => {
              if (disabled) {
                return;
              }

              const shouldClose = !!anchorEl;

              if (shouldClose) {
                closeDialog();
              } else {
                flushSync(() => setAnchorEl(e.currentTarget));

                if (onOpen) {
                  setTimeout(() => onOpen(), 0);
                }
              }

              buttonProps?.onClick?.(e);
            }}
          >
            {title}
          </ButtonComponent>

          <Popup
            placement={placement}
            anchor={anchorEl}
            open={!!anchorEl}
            className={twMerge("z-40", popupClassName)}
            onKeyDown={onPopupKeyDown}
          >
            <Card
              tabIndex={0}
              ref={cardRef}
              className={twMerge(
                "mt-0.25 flex h-full max-h-[50vh] animate-growInDown p-2 focus-within:outline-none",
                BUTTON_DIALOG_CARD_IDENTIFIER,
                cardClassName
              )}
              elevation="medium"
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowDown" &&
                  document.activeElement?.classList.contains(BUTTON_DIALOG_CARD_IDENTIFIER) &&
                  internalButtonRef.current &&
                  !isOpen
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  open(internalButtonRef.current);
                  return;
                }

                if (e.key === "Escape") {
                  e.preventDefault();
                  e.stopPropagation();
                  close();
                  internalButtonRef.current?.focus();
                  return;
                }

                onCardKeyDown?.(e);
              }}
              onClick={onCardClick}
            >
              {typeof children === "function" ? children(closeDialog, isOpen) : children}
            </Card>
          </Popup>
        </div>
      </ClickAwayListener>
    );
  }
);

export default ButtonDialog;

export type ButtonDialogState = ReturnType<typeof useButtonDialogState>;

export const useButtonDialogState = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const close = () => {
    setTimeout(() => setAnchorEl(undefined), 0);
  };

  const open = (_anchorEl: HTMLButtonElement) => setAnchorEl(_anchorEl);

  return {
    close,
    open,
    setAnchorEl: open,
    anchorEl,
    isOpen: !!anchorEl,
    isClosed: !anchorEl,
  };
};
