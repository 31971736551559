import { useFetcher as useRemixFetcher } from "@remix-run/react";
import usePrevious from "@tamarack/shared/hooks/usePrevious";
import type { AppUrl } from "../AppUrl";

export type Fetcher<T> = ReturnType<typeof useFetcher<T>>;

type FetcherOptions = Parameters<typeof useRemixFetcher>[0] & {
  cache?: boolean;
};

export default function useFetcher<T>(opts?: FetcherOptions) {
  if (opts?.cache && !opts?.key) {
    throw new Error("No key provided for cached fetcher");
  }

  const fetcher = useRemixFetcher<T>(opts);
  const previousNavigationState = usePrevious(fetcher.state);
  const previousKey = usePrevious(opts?.key);

  const isActionSubmission = fetcher.state === "submitting";
  const isActionReload =
    fetcher.state === "loading" && fetcher.formMethod != null && fetcher.formMethod === "POST";
  const submitted = previousNavigationState === "submitting" && fetcher.state === "loading";
  const submitting = isActionSubmission || isActionReload;

  function load(url: string | AppUrl) {
    /**
     * Cache the data if the key is the same. Remix doesn't do this by default. The key is used
     * for looking up the fetcher when useing useFetchers() (see https://remix.run/docs/en/main/hooks/use-fetcher#key)
     */
    if (opts?.cache && opts?.key && previousKey === opts.key && fetcher.data) {
      return;
    }

    fetcher.load(url.toString());
  }

  return {
    fetcher,
    submitted,
    loaded: submitted,
    loading: fetcher.state === "loading",
    submitting,
    Form: fetcher.Form,
    data: fetcher.data,
    load,
    submit: fetcher.submit,
    idle: fetcher.state === "idle",
    neverLoaded: fetcher.state === "idle" && !fetcher.data,
  };
}
