import { isRouteErrorResponse } from "@remix-run/react";
import Logo from "@tamarack/ui/Logo";
import Forest from "@tamarack/ui/images/Forest";
import type { PropsWithChildren, ReactNode } from "react";
import { useEffect, useState } from "react";

type ApplicationErrorProps = PropsWithChildren & {
  error: any;
  title: ReactNode;
  subtitle?: string;
};

export default function ApplicationError({
  error,
  title,
  subtitle,
  children,
}: ApplicationErrorProps) {
  const [shouldRender, setShouldRender] = useState(false);

  /*
    Don't render UI in browser until after inital React render to avoid flash of error
    content
  */
  useEffect(() => {
    setShouldRender(true);
  }, []);

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      console.error("🌲🌲🌲 Tᴀᴍᴀʀᴀᴄᴋ Aᴘᴘʟɪᴄᴀᴛɪᴏɴ Eʀʀᴏʀ 🌲🌲🌲:", error);
    }
  }, [error]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="bg-tm-background relative flex h-full min-h-[600px] w-full min-w-0 items-center justify-center">
      <div className="border-tm-divider p-d mx-d relative h-[500px] w-[600px] overflow-hidden rounded-xl border bg-white">
        <Forest
          className="absolute bottom-0 left-1/2 top-0 z-0 -translate-x-1/2 transform"
          svgClassName="w-full opacity-20"
        />

        <div className="py-d relative z-10 flex flex-col items-center justify-center gap-4 text-center">
          <Logo className="mb-d" />

          <>
            <h1 className="text-h4">{title}</h1>
            {subtitle ? (
              <h3 className="text-h5 text-tm-secondary-lighter font-normal">{subtitle}</h3>
            ) : null}

            <div className="gap-d flex flex-col items-center pt-5">{children}</div>
          </>
        </div>
      </div>
    </div>
  );
}
