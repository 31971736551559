import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import type { ButtonProps } from "./Button";
import Button from "./Button";

export type IconButtonProps = ButtonProps;

export default forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { className, children, ...buttonProps },
  ref
) {
  return (
    <Button
      ref={ref}
      size="small"
      variant="text"
      {...buttonProps}
      className={twMerge(
        "group !h-auto !px-[4px] !py-[4px]",
        buttonProps.size === "touch" ? "!px-[8px] !py-[8px]" : "",
        className
      )}
    >
      {children}
    </Button>
  );
});
