import type { CSSProperties, HTMLProps, PropsWithChildren, RefObject } from "react";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

export type CardProps = PropsWithChildren & {
  elevation?: "none" | "small" | "medium" | "large" | "xlarge" | "fast" | "xxlarge";
  className?: string;
  as?: "div" | "span" | "article" | "section";
  style?: CSSProperties;
  onMouseEnter?: HTMLProps<HTMLDivElement>["onMouseEnter"];
  onMouseLeave?: HTMLProps<HTMLDivElement>["onMouseEnter"];
  tabIndex?: 0 | -1;
  onKeyDown?: HTMLProps<HTMLDivElement>["onKeyDown"];
  onClick?: HTMLProps<HTMLDivElement>["onClick"];
};

const Card = forwardRef(({ as, className, elevation = "fast", ...props }: CardProps, ref) => {
  const Component = as ?? "div";

  return (
    <Component
      ref={ref as RefObject<HTMLDivElement>}
      {...props}
      className={twMerge(
        "rounded-xl border border-tm-divider bg-white",
        elevation === "small"
          ? "shadow-sm"
          : elevation === "medium"
            ? "shadow-md"
            : elevation === "large"
              ? "shadow-lg"
              : elevation === "xlarge"
                ? "shadow-xl"
                : elevation === "xxlarge"
                  ? "shadow-2xl"
                  : elevation === "none"
                    ? "shadow-none"
                    : elevation === "fast"
                      ? "border-b-2 border-b-tm-divider shadow-none"
                      : "shadow",
        "contain-paint print:border-tm-secondary print:shadow-none",
        className
      )}
    />
  );
});

export default Card;
