import {
  // eslint-disable-next-line no-restricted-imports
  useNavigation as useRemixNavigation,
} from "@remix-run/react";
import { AppUrl } from "../AppUrl";
import usePrevious from "./usePrevious";

export default function useNavigation() {
  const navigation = useRemixNavigation();
  const previousNavigationState = usePrevious(navigation.state);

  const isActionSubmission = navigation.state === "submitting";
  const isActionReload =
    navigation.state === "loading" &&
    navigation.formMethod != null &&
    navigation.formMethod === "POST";

  const submitted = previousNavigationState === "submitting" && navigation.state === "idle";
  const submitting = isActionSubmission || isActionReload;

  return {
    navigation,
    submitted,
    loaded: submitted,
    loading: navigation.state === "loading",
    submitting,
    idle: navigation.state === "idle",
    location: navigation.location,
    state: navigation.state,
    isUrlLoading(nextUrl: AppUrl, includeSearchParams = false) {
      if (!navigation.location) {
        return false;
      }

      const currentUrl = new AppUrl(
        `${navigation.location.pathname}${includeSearchParams ? navigation.location.search : ""}`
      );

      if (!includeSearchParams) {
        nextUrl = nextUrl.clearSearchParams();
      }

      return navigation.state === "loading" && currentUrl.matches(nextUrl);
    },
  };
}
