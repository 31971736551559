import { twMerge } from "tailwind-merge";
import type { ButtonVaraint, Size } from "./Button";

type DividerProps = {
  className?: string;
  orientation?: "horizontal" | "vertical";
  size?: Size;
  backgroundVariant?: ButtonVaraint;
};

export default function Divider({
  size = "small",
  className,
  orientation = "horizontal",
  backgroundVariant,
}: DividerProps) {
  return (
    <hr
      className={twMerge(
        "inline-block h-px w-full border-t-0 bg-tm-divider",
        orientation === "vertical" ? "h-3 w-px" : undefined,
        size === "small" && orientation === "vertical"
          ? "h-[28px]"
          : size === "medium"
            ? "h-[34px]"
            : size === "large"
              ? "h-6"
              : size === "touch"
                ? "h-[110px]"
                : "h-px",
        backgroundVariant === "primary"
          ? "bg-tm-primary-lightest"
          : backgroundVariant === "secondary"
            ? "bg-tm-secondary-lighter"
            : backgroundVariant === "danger"
              ? "bg-tm-error-lightest"
              : backgroundVariant === "info"
                ? "bg-tm-info-lightest"
                : undefined,
        className
      )}
    />
  );
}
