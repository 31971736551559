import type { ErrorResponse, LinksFunction, MetaFunction } from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import ApplicationError from "@tamarack/shared/ApplicationError";
import Button from "@tamarack/ui/Button";
import tailwindStylesheet from "@tamarack/ui/tailwind.css?url";
import { SnackbarProvider } from "@tamarack/ui/utils/snackbar";
import { Provider as JotaiProvider } from "jotai";
import { useContext } from "react";
import appleTouchIcon from "./images/apple-touch-icon.png";
import favicon from "./images/favicon.png";
import { NonceContext } from "./nonce";
import { appStore } from "./stores/app";

export const meta: MetaFunction = () => {
  return [{ title: "Tamarack Software" }];
};

export const links: LinksFunction = () => {
  return [
    { rel: "apple-touch-icon", sizes: "180x180", href: appleTouchIcon },
    { rel: "icon", type: "image/png", sizes: "32x32", href: favicon },
    { rel: "stylesheet", href: tailwindStylesheet },
  ];
};

function Root() {
  const nonce = useContext(NonceContext);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Tamarack is the leading cloud-based software provider for lumber and logging operations."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <meta name="theme-color" content="#f6f7f8" />
        <meta name="keywords" content="logging lumber sawmill" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.tamaracksoftware.com/" />
        <meta property="og:site_name" content="tamaracksoftware.com" />
        <meta property="article:publisher" content="https://www.tamaracksoftware.com/" />
        <meta property="og:title" content="Tamarack Software" />
        <meta
          property="og:description"
          content="Tamarack is the leading cloud-based software provider for lumber and logging operations."
        />
        <Meta />
        <Links />
      </head>

      <body>
        <JotaiProvider store={appStore}>
          <SnackbarProvider>
            <Outlet />
          </SnackbarProvider>
        </JotaiProvider>

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

export default withSentry(Root);

export function ErrorBoundary() {
  const error = useRouteError() as { data: ErrorResponse; status: number };

  captureRemixErrorBoundaryError(error);

  return (
    <ApplicationError
      error={error}
      title="Something went wrong"
      subtitle="There was an error while loading the application"
    >
      <form method="get">
        <Button type="submit" variant="primary">
          Reload the page
        </Button>
      </form>
    </ApplicationError>
  );
}
